<script setup lang="ts" generic="T extends Date | [Date, Date]">
  import {
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuPortal,
    DropdownMenuRoot,
    DropdownMenuTrigger,
    DropdownMenuSeparator,
  } from 'radix-vue'
  import { ref } from 'vue'
  import MenuDotsIcon from '@/assets/icons/MenuDots.svg'
  import { ClassProp } from '../types/props'
  import { MenuOption } from './baseMenu'

  defineProps<{
    options: MenuOption[]
    toggleClass?: ClassProp
    panelClass?: ClassProp
    itemClass?: ClassProp
  }>()

  const toggleState = ref(false)
</script>

<template>
  <div class="cursor-pointer" data-testid="base-menu">
    <DropdownMenuRoot v-model:open="toggleState">
      <DropdownMenuTrigger
        aria-label="Base Menu Options Trigger"
        class="base-control disabled:not-allowed hover:opacity-control p-0"
        :class="toggleClass"
        data-testid="base-menu-button"
      >
        <slot>
          <MenuDotsIcon />
        </slot>
      </DropdownMenuTrigger>

      <DropdownMenuPortal>
        <DropdownMenuContent
          class="light:shadow-lg border-gray-var-700 bg-black-var z-40 cursor-pointer rounded-lg border p-3 shadow-2xl outline-none"
          :class="panelClass"
          data-testid="base-menu-panel"
          :side-offset="5"
        >
          <template
            v-for="({ title, action, icon, separator }, key) in options"
            :key
          >
            <DropdownMenuItem
              v-if="!separator"
              class="hover:opacity-control group relative flex select-none p-2"
              :class="itemClass"
              :value="title"
              @click="action"
            >
              <div class="flex flex-row items-center gap-4 text-left">
                <component :is="icon" v-if="icon" class="h-5.5 w-5.5" />
                {{ title }}
              </div>
            </DropdownMenuItem>
            <DropdownMenuSeparator
              v-else
              class="bg-gray-var-700 m-[5px] h-[1px]"
            />
          </template>
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenuRoot>
  </div>
</template>
